/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ErrorComponent } from '../pages/error-modal/error-modal';
import { Const } from './const';
import { LoadingController, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ModalOption } from './servicer/models/modal-option';
import { M1SorryButPage } from '../pages/m1-sorry-but/m1-sorry-but';


@Injectable({
  providedIn: 'root'
})
export class ModalControl {
  public openErrorModalFlag= new Subject();

  private creatingModal: string = null;
  private openingModal: string = null;

  private readonly stopLoadingTime = 500;

  private modalTypeList = [
    {type: 'error', priority: 1},
    {type: 'login', priority: 2},
    {type: 'gps', priority: 2}
  ];

  constructor(
    private translate: TranslateService,
    private modalCtrl: ModalController,
    private loadingCtrl: LoadingController
  ) {}

  /** モーダルを開くか判断. */
  public openModal(type: string, option?: ModalOption): boolean {
    const priority = this.modalTypeList.find(value => value.type.includes(type)).priority;
    //モーダルタイプがエラーでオプションがない場合は失敗を返す
    if (type === Const.MODAL_TYPE_ERROR && (!option || option === null)){
      return false;
    }
    //開いているモーダルがある場合
    if (this.openingModal) {
      const openModalPriority = this.modalTypeList.find(value => value.type.includes(this.openingModal)).priority;
      //新しいモーダルの優先度が高ければ、開いているモーダルを閉じて新しいモーダルを開く
      if (openModalPriority >= priority) {
        this.stopModal(this.openingModal);
        this.selectOpenModal(type, option);
      }
      return openModalPriority >= priority;
    //作成中のモーダルがある場合
    } else if (this.creatingModal) {
        const createModalPriority = this.modalTypeList.find(value => value.type.includes(this.creatingModal)).priority;
        //作成中のモーダルの優先度が新しいモーダルより低ければ、モーダルを表示しないフラグを立てる、新しいモーダルを開く
        if (createModalPriority >= priority) {
          this.creatingModal = type;
          this.selectOpenModal(type, option);
        }
        return createModalPriority >= priority;
    } else {
      this.selectOpenModal(type, option);
      return true;
    }
  }

  /** モーダル 停止. */
  public stopModal(type: string) {
    if (type === this.creatingModal) {
      this.creatingModal = null;
    }
    if (type === this.openingModal) {
      if (type === Const.MODAL_TYPE_LOGIN) {
        setTimeout(() => {
          // 画面遷移後にローディングを停止
          this.loadingCtrl.dismiss();
        }, this.stopLoadingTime);
      } else {
        this.modalCtrl.dismiss();
      }
      this.openingModal = null;
    }
  }

  /** 表示するモーダルを選択. */
  private selectOpenModal(type: string, option?: ModalOption) {
    this.creatingModal = type;
    switch (type) {
      case Const.MODAL_TYPE_ERROR:
        if (option && option !== null) {
          this.openErrorModal(option.title, option.message, option.isCallCenter);
        }
        break;
      case Const.MODAL_TYPE_LOGIN:
        this.openLoadingModal();
        break;
      case Const.MODAL_TYPE_GPS:
        this.openGpsModal();
        break;
    }
  }

  /** エラーモーダルを表示. */
  private openErrorModal(title: string, message: string, isCallCenter: boolean) {
    this.openErrorModalFlag.next();
    this.modalCtrl.create({
      id: ErrorComponent.MODAL_ID,
      component: ErrorComponent,
      componentProps: {title, message, isCallCenter},
      cssClass: Const.MODAL_FULL,
      backdropDismiss: false
    }).then((response) => {
      this.modalPresent(Const.MODAL_TYPE_ERROR, response);
    });
  }

  /** Loading モーダルを表示. */
  private openLoadingModal() {
    this.loadingCtrl.create({
      spinner: null,
      message: '<img src="/assets/img/login/ukedon-searching.sw.cache.png"><p class="font90">' + this.translate.instant('get_the_location') + '</p>',
      cssClass: 'login-loading'
    }).then((response) => {
      this.modalPresent(Const.MODAL_TYPE_LOGIN, response);
    });
  }

  /** GPSモーダルを表示. */
  private openGpsModal() {
    this.modalCtrl.create({
      id: M1SorryButPage.MODAL_ID,
        component: M1SorryButPage,
        componentProps: null,
        cssClass: Const.MODAL_FULL,
        backdropDismiss: false
    }).then((response) => {
        this.modalPresent(Const.MODAL_TYPE_GPS, response);
    });
  }

  private modalPresent(type: string, modal: HTMLIonModalElement |HTMLIonLoadingElement) {
    if (this.creatingModal === type) {
      modal.present();
      this.openingModal = type;
      this.creatingModal = null;
    }
  }
}
