import { Component, ViewEncapsulation } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { AlertController, AlertOptions, ViewWillLeave } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Environment } from 'src/environments/environment';
import { PwaUpdateEvent, UpdateService } from '../../services/update-service';
import { StorageWrapper } from 'src/app/services/storage-wrapper';
import { Const } from 'src/app/services/const';
import * as Moment from 'moment';
import { AlertType } from 'src/app/services/servicer/models/update-alert-model';
import { Observable, Subscription, of, throwError, BehaviorSubject } from 'rxjs';
import { mergeMap, retry } from 'rxjs/operators';
import { VersionInfo } from 'src/app/types/version-info';
import { HttpRequestor } from 'src/app/services/http-requestor';

@Component({
  selector: 'app-comp-updates',
  templateUrl: 'comp-updates.html',
  styleUrls: ['comp-updates.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CompUpdatesAlertComponent implements ViewWillLeave {

  //更新先無し発生時のフラグ
  private isCheckForUpdateNotFound: boolean;

  //unsubscribe用のリスト
  private subscriptions = new Subscription();

  //更新のタイムアウト時間
  private timeoutUpdating: NodeJS.Timeout;

  //タイムオーバーフラグ
  private isTimeOver: boolean;

  //自動ログインの文字列
  private readonly autoLoginStr = 'autoLogin';

  /** 表示なし */
  private readonly alertIdNone = 'NONE';

  /** 現在時刻 */
  private todayDate: string;

  private readonly successHttpStatus = 200;

  /** 更新成功 */
  private readonly alertIdUpdateSuccess: AlertType = {
    header: '',
    message: 'update_success',
    alertId: 'ALERT_UPDATE_SUCCESS',
    scssName: 'versionUpNoButton',
    alertTimeout: new BehaviorSubject<boolean>(false)
  };

  /** 更新 */
  private readonly alertIdWithUpdates: AlertType = {
    header: '',
    message: 'detect_new_version_of_pwa',
    alertId: 'ALERT_UPDATE_CONFIRM',
    scssName: 'versionUpNoButton',
    alertTimeout: new BehaviorSubject<boolean>(false)
  };

  /** 更新中 */
  private readonly alertIdUpdating: AlertType = {
    header: 'updating_pwa',
    message: '',
    alertId: 'ALERT_UPDATING',
    scssName: 'versionUpdating',
    alertTimeout: new BehaviorSubject<boolean>(false)
  };

  /** 更新失敗 */
  private readonly alertIdFailed: AlertType = {
    header: '',
    message: 'update_failed',
    alertId: 'ALERT_FAILED',
    scssName: 'updateFailed',
    button: [
      {
        text: 'OK',
        role: 'OK',
        handler: () => {
          //ブラウザ更新
          document.location.reload();
        }
      }
    ],
    alertTimeout: new BehaviorSubject<boolean>(false)
  };

  /** 現在表示画面 */
  private isShowAlertId = this.alertIdNone;

  constructor(
    private alertCtrl: AlertController,
    private swUpdate: SwUpdate,
    private translate: TranslateService,
    private updateService: UpdateService,
    private storageWrapper: StorageWrapper,
    private httpReq: HttpRequestor,
  ) {
    this.updateService.setSwUpdate(this.swUpdate);
    this.updateService.setUpSwUpdateSubscriber();
  }

  ionViewWillLeave(): void {
    if (this.subscriptions) {
      if (!this.subscriptions.closed) {
        this.subscriptions.unsubscribe();
      }
    }
  }

  /**バージョンアップ確認のメイン関数 */
  public async checkForUpdateVersion() {
    //ServiceWorkerの起動確認
    if (!this.swUpdate.isEnabled) {
      // 起動して居なければ更新確認すらしない
      this.updateService.checkUpdateFinish.next(this.autoLoginStr);
      return;
    }

    this.todayDate = Moment(new Date()).format('YYYY/MM/DD');

    //ストレージから前回起動日付を取得
    this.getOpenAppDate().then((storageDate: string) => {
      if (storageDate) {
        if (storageDate === this.todayDate) {
          //自動ログイン起動
          this.updateService.checkUpdateFinish.next(this.autoLoginStr);
          return;
        }
      }
      //S3サーバーからバージョン取得とバージョン比較処理
      this.subscriptions.add(this.tryGetVersionNoForS3().subscribe(async (s3Version: string) => {
        if (s3Version === Environment.appVersion) {
          this.getIsFailedUpdateFlag().then((failedFlag: boolean) => {
            if (!failedFlag) {
              // S3バージョンと一致かつ、前回失敗アラートを表示して居なければ自動ログイン起動
              this.storageWrapper.setStorage(Const.STORAGE_KEY_LAST_OPEN_APP_DATE, this.todayDate);
              this.updateService.checkUpdateFinish.next(this.autoLoginStr);
            } else {
              // S3バージョンと一致したが前回失敗アラートを出していた場合は一連のアラートを表示する
              // ※更新事態は成功しているがユーザーに通知できていないのでアラートのみ表示、更新事態は実行しない
              this.storageWrapper.removeStorage(Const.STORAGE_KEY_IS_UPDATE_FAILED).then(() => {
                //「更新があります」アラートの表示
                this.showAlertModal(this.alertIdWithUpdates);

                //更新中アラートの表示
                this.showAlertModal(this.alertIdUpdating);

                //更新成功アラートの表示
                this.showAlertModal(this.alertIdUpdateSuccess);

                this.subscriptions.add(this.alertIdUpdateSuccess.alertTimeout.subscribe((reloadFlag: boolean) => {
                  if (reloadFlag) {
                    document.location.reload();
                  }
                }));
              });
            }
          });
        } else {
          //「更新があります」アラートの表示
          this.showAlertModal(this.alertIdWithUpdates);

          //更新中アラートの表示
          this.showAlertModal(this.alertIdUpdating);
          this.tryUpdate();
        }
      }));

    });
  }

  /**
   * 更新処理中状態
   *
   * @returns true:更新処理中 , false: 更新未動作
   */
  public isUpdating(): boolean {
    if (this.isShowAlertId === this.alertIdNone) {
      return false;
    }
    return true;
  }

  /** ストレージから、アプリ最終起動日付を取得 */
  private async getOpenAppDate(): Promise<string> {
    return await this.storageWrapper.getStorage(Const.STORAGE_KEY_LAST_OPEN_APP_DATE);
  }

  /** ストレージから、更新失敗フラグを取得 */
  private async getIsFailedUpdateFlag(): Promise<boolean> {
    return await this.storageWrapper.getStorage(Const.STORAGE_KEY_IS_UPDATE_FAILED);
  }

  /**
   * s3のバージョン番号ファイルの値を取得,取得に失敗した場合は、再度取得を試みる（リトライは、5回まで）
   *
   * @returns s3バージョン
   */
  private tryGetVersionNoForS3(): Observable<string> {
    return new Observable<string>((res) => {
      const getS3VerResult: Observable<string> = this.getVersionNoForS3().pipe(
        mergeMap(s3Ver => s3Ver ? of(s3Ver) : throwError('failed getS3Ver')),
        retry(4)
      );

      this.subscriptions.add(getS3VerResult.subscribe({
        next: s3Ver => res.next(s3Ver),
        //通信障害アラートモーダル表示 S3の取得に失敗した場合は更新中アラートを待たずに更新失敗を表示する
        error: () => this.showAlertModal(this.alertIdFailed, true)
      }));
    });
  }


  /**
   * s3のバージョン番号ファイルの値を取得する
   *
   * @returns s3から取得したバージョン番号
   */
  private getVersionNoForS3(): Observable<string> {
    return new Observable<string>((s3Ver) => {
      fetch(Environment.addressVersionUrl, {
        method: 'GET'
      }).then(async res => res.status === this.successHttpStatus ?
        s3Ver.next(this.getVersionNoForJsonFile(await res.text())) : s3Ver.next(null));
    });
  }

  /**
   * バージョン管理ファイルをJSONデータに変換後、パース処理をしてバージョン番号を取得する
   *
   * @param versionFile s3から取得したバージョン管理ファイル
   * @returns バージョン番号
   */
  private getVersionNoForJsonFile(versionFile: string): string {
    try {
      const resJson = JSON.parse(versionFile) as VersionInfo;
      const envArea = Environment.type;

      switch (envArea) {
        //SPOKE環境を起動している場合、バージョン管理ファイルからSPOKE環境のバージョン番号を取得する
        case 'devSpoke':
        case 'prdSpoke':
          return resJson.spoke.nimo;
        //HUB環境を起動している場合、バージョン管理ファイルからHUB環境のバージョン番号を取得する
        case 'devHub':
        case 'prdHub':
          return resJson.hub.nimo;
        default:
          return null;
      }
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  /**
   * 更新情報取得と、更新実行を3回まで実行する
   */
  private async tryUpdate(): Promise<void> {
    const updateResult: Observable<boolean> = this.update().pipe(
      mergeMap(isSuccess => {
        //タイムアウト処理をクリア
        if (this.timeoutUpdating) {
          clearTimeout(this.timeoutUpdating);
        }
        //更新情報取得と更新成功
        if (isSuccess) {
          return of(isSuccess);
        } else {
          throwError(() => 'failed update');
        }
      }),
      retry(2)
    );

    this.subscriptions.add(updateResult.subscribe(
      () => {
        // アップデートに成功
        this.showAlertModal(this.alertIdUpdateSuccess);
        this.storageWrapper.setStorage(Const.STORAGE_KEY_LAST_OPEN_APP_DATE, this.todayDate);
        this.subscriptions.add(this.alertIdUpdateSuccess.alertTimeout.subscribe((reloadFlag: boolean) => {
          if (reloadFlag) {
            document.location.reload();
          }
        }));
      },
      // アップデート失敗
      (error) => {
        console.error(error);
        this.subscriptions.add(this.alertIdUpdating.alertTimeout.subscribe((reloadFlag: boolean) => {
          if(this.isCheckForUpdateNotFound && reloadFlag){
            this.storageWrapper.removeStorage(Const.STORAGE_KEY_IS_UPDATE_FAILED);
            this.storageWrapper.setStorage(Const.STORAGE_KEY_LAST_OPEN_APP_DATE, this.todayDate);
            document.location.reload();
          }
        }));
        if(!this.isCheckForUpdateNotFound){
          this.showAlertModal(this.alertIdFailed);
        }
      }
    ));
  }

  /**更新情報取得と、更新実行 */
  private update(): Observable<boolean> {
    return new Observable<boolean>((observe) => {
      //タイムアウトフラグを初期化
      this.isTimeOver = false;

      //タイムアウト起動
      this.timeoutUpdating = setTimeout(() => {
        this.isTimeOver = true;
        observe.next(false);
      }, Environment.pwaUpdatingTimeout);

      this.isGetUpdateInfoSuccess().then(async (updateInfoFlag: boolean) => {
        //更新情報取得成功
        if (updateInfoFlag && !this.isTimeOver) {
          observe.next(true);
        } else {
          observe.next(false);
        }
      });
    });
  }

  /**
   * 更新確認（ServiceWorker)
   *
   */
  private async isGetUpdateInfoSuccess(): Promise<boolean> {
    // 更新情報取得
    const evt: PwaUpdateEvent = await this.updateService.pwaUpdateConfirm();
    this.uploadCheckFroUpdateLog(evt);
    return evt === PwaUpdateEvent.eventCheckForUpdateFound && !this.isTimeOver;
  }

  /**
   * 暫定対策：
   * 　PWA更新確認を行った際の戻り値が更新先なしの場合、ログインAPIを実行し、CloudWatchからログ確認できるように対応
   */
  private uploadCheckFroUpdateLog(logStr: PwaUpdateEvent): void {
    //初期化
    this.isCheckForUpdateNotFound = false;

    if (logStr === PwaUpdateEvent.eventCheckForUpdateNotFound) {
      this.isCheckForUpdateNotFound = true;
    }
    const password = '';
    const appVersion = '';
    const updateFailedLog = Moment().format('YYYY/MM/DD HH:mm:ss') + '；'
      + Environment.appVersion + '；' + logStr + '；' + window.navigator.userAgent;

    this.storageWrapper.getStorage(Const.STORAGE_KEY_LOGIN_ID).then((userId: string) => {
      this.httpReq.postLoginRequest(
        userId,
        password,
        updateFailedLog,
        appVersion
      );
    });
  }

  /**
   * targetAlertの生成
   *
   * @param targetAlert 表示するアラートタイプ
   */
  private showAlertModal(targetAlert: AlertType, skipFlg?: boolean): void {
    if (Environment.operationDuringPwaUpdate) {
      return;
    }
    this.isShowAlertId = targetAlert.alertId;

    let translateStr = '';

    //ヘッダーが空以外の場合、ヘッダーに書かれている名前をtranslateStrに代入
    if (targetAlert.header) {
      translateStr = targetAlert.header;
    } else {
      translateStr = targetAlert.message;
    }

    this.subscriptions.add(
      this.translate.get([translateStr]).subscribe(async (valueMsg: string) => {
        // 画面情報生成
        const alertOption: AlertOptions = {
          id: targetAlert.alertId,
          header: valueMsg[targetAlert.header],
          message: valueMsg[targetAlert.message],
          cssClass: targetAlert.scssName,
          buttons: targetAlert.button,
          translucent: true,
          backdropDismiss: false,
          animated: false,
        };

        this.subscriptions.add(
          this.createAlert(alertOption).subscribe((createdAlert) => {
            if (targetAlert.alertId === this.alertIdWithUpdates.alertId) {
              createdAlert.present();
              setTimeout(() => this.alertIdWithUpdates.alertTimeout.next(true), Environment.pwaDisplayTimeout);
            }
            if (targetAlert.alertId === this.alertIdUpdating.alertId) {
              this.presentAlert(createdAlert, this.alertIdUpdating, this.alertIdWithUpdates);
            }
            if (targetAlert.alertId === this.alertIdFailed.alertId) {
              this.presentAlert(createdAlert, this.alertIdFailed, this.alertIdUpdating, skipFlg);
              //ストレージに更新失敗フラグを保存
              this.storageWrapper.setStorage(Const.STORAGE_KEY_IS_UPDATE_FAILED, true);
            }
            if (targetAlert.alertId === this.alertIdUpdateSuccess.alertId) {
              this.presentAlert(createdAlert, this.alertIdUpdateSuccess, this.alertIdUpdating);
            }
          })
        );
      })
    );
  }
  /**
   *　アラートを生成
   *
   * @param alert 生成に必要な情報
   * @returns 生成したアラートのインスタンス
   */
  private createAlert(alert: AlertOptions): Observable<HTMLIonAlertElement> {
    return new Observable<HTMLIonAlertElement>((res) => {
      this.alertCtrl.create(alert).then((createdAlert) => {
        res.next(createdAlert);
      });
    });
  }
  /**
   *　waitingAlertの最低表示時間経過後、targetAlertを表示
   *
   * @param createdAlert 生成したアラートのインスタンス
   * @param targetAlert 表示するアラートタイプ
   * @param waitingAlert 閉じるアラートタイプ
   * @param skipFlg 閉じるアラートの最低表示時間を待たずに表示アラート表示する場合にTrue
   */
  private presentAlert(createdAlert: HTMLIonAlertElement, targetAlert: AlertType, waitingAlert: AlertType, skipFlg?: boolean): void {
    if (!skipFlg) {
      this.subscriptions.add(
        waitingAlert.alertTimeout.subscribe((res) => {
          if (res) {
            createdAlert.present();
            this.alertCtrl.dismiss(undefined, undefined, waitingAlert.alertId);
            setTimeout(() => targetAlert.alertTimeout.next(true), Environment.pwaDisplayTimeout);
          }
        })
      );
    } else {
      createdAlert.present();
    }
  }
}
