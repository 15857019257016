import { Platform } from '@ionic/angular';
import { Injectable } from '@angular/core';
@Injectable({
      providedIn: 'root'
})

export class IosAndroidCheck {

    constructor(
        public platform: Platform
    ){
    }
    /**
     * 端末OSの種類を取得
     *
     * @returns true：ios false: android
     */
    public checkIsIos(): boolean{
        return this.platform.is('ios');
    }
}
