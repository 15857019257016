import { Component, OnInit, ViewChild } from '@angular/core';
import { NavController, ModalController } from '@ionic/angular';
import { LanguageSetup } from '../../services/language-setup';

import { TranslateService } from '@ngx-translate/core';
import { LogUtil } from '../../utilities/log-util';
import { Environment } from 'src/environments/environment';
import { IosAndroidCheck } from 'src/app/services/ios-android-check.service';
import { CompTroubleComponent } from 'src/app/components/comp-trouble/comp-trouble';


@Component({
  selector: 'page-m1-sorry-but',
  templateUrl: 'm1-sorry-but.html',
  styleUrls: ['m1-sorry-but.scss']
})
export class M1SorryButPage implements OnInit {
  public static readonly MODAL_ID = "M1SorryButPage";
  @ViewChild(CompTroubleComponent) compTrouble: CompTroubleComponent;
  public isIos = false;
  /** 環境ステータス. */
  protected envStatus: string;
  private logger: LogUtil = new LogUtil("18","SorryBut");

  constructor(public navCtrl: NavController,
    public langSetup: LanguageSetup,
    public translate: TranslateService,
    public modalCtrl: ModalController,
    private osCheck: IosAndroidCheck ) {
    this.logger.pageLog();
  }

  ngOnInit() {
    this.envStatus = Environment.type;
    this.isIos = this.osCheck.checkIsIos();
  }

  onTapButton() {
    this.modalCtrl.dismiss(null,null,M1SorryButPage.MODAL_ID);
  }
}
