import { Component, OnInit, ViewChild } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import {
  NavController,
  Platform,
  ViewWillEnter,
  MenuController,
  AlertController
} from '@ionic/angular';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { Router } from '@angular/router';

import { LanguageSetup } from './services/language-setup';
import { ViewEncapsulation } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Const } from './services/const';
import { StorageWrapper } from './services/storage-wrapper';
import { UserStatusManager } from './services/user-status-manager';
import { LogUtil } from './utilities/log-util';
import { Events } from './services/events';
import { UpdateService } from './services/update-service';
import { CompUpdatesAlertComponent } from './components/comp-updates/comp-updates';
import { HttpRequestor } from 'src/app/services/http-requestor';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements ViewWillEnter, OnInit {
  @ViewChild('mycontent') navCtrl: NavController;

  public debugMode = 'OFF';

  // 言語情報
  public langImg: string;

  private logger: LogUtil = new LogUtil('90', 'Menu');

  private updateComp: CompUpdatesAlertComponent;

  constructor(
    private swUpdate: SwUpdate,
    private router: Router,
    public platform: Platform,
    public statusBar: StatusBar,
    public splashScreen: SplashScreen,
    public scrOrient: ScreenOrientation,
    public storageWrapper: StorageWrapper,
    public langSetup: LanguageSetup,
    public translate: TranslateService,
    public events: Events,
    public userStatusManager: UserStatusManager,
    private menu: MenuController,
    private alertCtrl: AlertController,
    private updateService: UpdateService,
    private httpReq: HttpRequestor,
  ) {
    if (platform.is('cordova')) {
      this.scrOrient.lock(this.scrOrient.ORIENTATIONS.PORTRAIT);
    }

    platform.backButton.subscribe(() => {
      // code that is executed when the user pressed the back button
    });

    platform.ready().then(() => {
      // Okay, so the platform is ready and our plugins are available.
      // Here you can do any higher level native things you might need.

      statusBar.styleDefault();
      splashScreen.hide();
    });

    this.onEvent();
  }
  ionViewWillEnter(): void {
    this.setLanguageImage();
  }

  /**
   * イベント処理
   *
   * @note 本サブスクライバはアプリ起動中はクリアしてはならない。クリアした場合、サイドメニュが動作不良を起こす。
   */
  onEvent() {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.setLanguageImage();
    });
  }

  changeDebug() {
    if (this.debugMode === 'ON') {
      this.debugMode = 'OFF';
      this.storageWrapper.setStorage('debugMode', this.debugMode);
    } else {
      this.debugMode = 'ON';
      this.storageWrapper.setStorage('debugMode', this.debugMode);
    }
  }

  // ページ読み込み開始時
  openMenu() {
    this.logger.pageLog();
    this.setLanguageImage();
  }

  setLanguageImage() {
    // 言語設定
    if (this.langSetup.lang === 'ja') {
      this.langImg = 'icon_ja';
    } else {
      this.langImg = 'icon_en';
    }
  }

  tapChangeAccount() {
    // ストレージを削除してログイン画面に遷移
    this.logger.tapLog('tapChangeAccount');
    this.toLoginPage();
    this.userStatusManager.finalize();
    this.toLoginPage();
  }

  async toLoginPage() {
    /*** ユーザーIDの削除 ***/
    await this.storageWrapper.removeStorage(Const.STORAGE_KEY_USER_ID);
    /*** ログインIDの削除 ***/
    await this.storageWrapper.removeStorage(Const.STORAGE_KEY_LOGIN_ID);
    /*** ログインパスワードの削除 ***/
    await this.storageWrapper.removeStorage(Const.STORAGE_KEY_LOGIN_PASSWORD);
    /*** ユーザアイコンURLの削除 ***/
    await this.storageWrapper.removeStorage(Const.STORAGE_KEY_USER_ICON);
    /*** ユーザー名の削除 ***/
    await this.storageWrapper.removeStorage(Const.STORAGE_KEY_USER_NAME);
    /*** 端末のFCMトークンを削除 ***/
    await this.storageWrapper.removeStorage(Const.STORAGE_KEY_TOKEN);

    this.menu.close();
    this.router.navigate(['login'], { replaceUrl: true });
  }

  async ngOnInit() {
    // ストレージ初期化
    await this.storageWrapper.init();
    // 言語設定
    await this.langSetup.load();

    this.updateComp = new CompUpdatesAlertComponent(
      this.alertCtrl,
      this.swUpdate,
      this.translate,
      this.updateService,
      this.storageWrapper,
      this.httpReq
    );

    document.addEventListener(
      'visibilitychange',
      async (ev) => {
        //アプリ再表示時に更新確認実行
        if (document.visibilityState === 'visible') {
          if (!this.updateComp.isUpdating()) {
            this.updateComp.checkForUpdateVersion();
          }
        }
      },
      false
    );

    //起動時に一度更新確認実行
    this.updateComp.checkForUpdateVersion();
  }
}
